import React from "react";
import {
    Container,
    Nav,
    Navbar,
    Image,
    Row
} from 'react-bootstrap';

export default function Navigation() {
    return (
        <>
            <Container>
                <Row >
                    <Image
                        src="/logoWithTextLight.png"
                        className="hero"
                    />
                </Row>
            </Container>
            <Container>
                <Navbar>
                    <Container id="nav-container">
                        <Nav.Link className="nav-link" href="#locations">Find a Game</Nav.Link>
                        <Nav.Link className="nav-link" href="#contact">Contact Us</Nav.Link>
                    </Container>
                </Navbar>
            </Container>
        </>
    )
}
import React from "react";
import { Container } from "react-bootstrap";


export default function Home(){
    return(
        <>
        <Container className="content" id="home">
            <h2>WELCOME!</h2>
            <p>
                Looking for a trivia night in Columbus? you've come to the right place! 
            </p>
            <p>
                Every game includes 18 questions to test your general knowledge
                and a chance to win prizes from one of your favorite neighborhood bars. 
                We always have a good time and it's always free to play.
            </p>
        </Container>
        </>
    )
}
import React from "react";
import { Card, Container, Button } from "react-bootstrap";
const locJson = require('../locations.json')
const locArray = locJson.locations

export default function Locations() {

    return (
        <>
        <h2>FIND A GAME</h2>
            <Container className="content" id="locations">
                {locArray.map((x, i) => {
                    return (
                        <Card className="loc-card" key={i}>
                            <Card.Img variant="top" src={x.image} />
                            <Card.Title>{x.name}</Card.Title>
                            <Card.Text>
                                {x.neighborhood}
                                <br/>
                                {x.day} @ {x.time}
                            </Card.Text>
                            <Container>
                            <Button className="card-btn" href={x.website}>Website</Button>
                            <Button className="card-btn" href={`https://www.google.com/maps/dir/?api=1&destination=${x.name}%2C+Columbus%2C+OH`} target='_blank'>Directions</Button>
                            </Container>
                        </Card>
                    )
                })}
            </Container>
        </>
    )
}
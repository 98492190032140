import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navigation from './components/navigation';
import Home from './pages/home';
import Locations from './pages/locations';
import Contact from './pages/contact';

function App() {
  return (
    <div className="App">
      <Navigation />
      <Home />
      <Locations />
      <Contact />
    </div>
  );
}

export default App;
